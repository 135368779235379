import React, { useState } from "react";
import s from "./ModalHelp.module.css";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import Button from "../../components/Button/Button";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import localization from "../../utils/localization";
import * as API from "../../services/api";

const ModalHelp = ({ onClose = () => null, language = "ru", token }) => {
  const [message, setMessage] = useState("");
  const hahdleChangeTextarea = (e) => setMessage(e.target.value);

  const [isFormSuccessSubmit, setIsFormSuccessSubmit] = useState(false);

  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);

  const handleSubmitForm = (e) => {
    e.preventDefault();

    API.needHelpRequest(token, message)
      .then((res) => {
        if (res.data.success) {
          setIsFormSuccessSubmit(true);
          setIsShowErrorMessage(false);
        } else {
          setIsShowErrorMessage(true);
        }
      })
      .catch(() => {
        setIsShowErrorMessage(true);
      });
  };

  return (
    <ModalWrapper onClose={onClose} hideCloseButton>
      <section className={s.wrapper}>
        <Close className={s.close} onClick={onClose} />

        {isFormSuccessSubmit ? (
          <>
            {/* form after success submit - start */}
            <h2 className={s.modalTitle}>
              {language === "ru"
                ? localization.ModalHelp.title2.ru
                : localization.ModalHelp.title2.ua}
            </h2>
            <div className={s.contentWrapper}>
              <p className={s.text}>
                {language === "ru"
                  ? localization.ModalHelp.text2.ru
                  : localization.ModalHelp.text2.ua}
              </p>
              <div className={s.button_container}>
                <a
                  href="https://t.me/goit_programmer_bot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    // onClickButton={startGameOrContinue}
                    text={
                      language === "ru"
                        ? localization.ModalHelp.buttonGoToBot.ru
                        : localization.ModalHelp.buttonGoToBot.ua
                    }
                    bigButton={true}
                    type="button"
                  />
                </a>
              </div>
            </div>
            {/* form before success submit - end */}
          </>
        ) : (
          <>
            {/* form before success submit - start */}
            <h2 className={s.modalTitle}>
              {language === "ru"
                ? localization.ModalHelp.title1.ru
                : localization.ModalHelp.title1.ua}
            </h2>
            <div className={s.contentWrapper}>
              <p className={s.text}>
                {language === "ru"
                  ? localization.ModalHelp.text1.ru
                  : localization.ModalHelp.text1.ua}
              </p>

              <form onSubmit={handleSubmitForm}>
                <textarea
                  className={s.textarea}
                  value={message}
                  onChange={hahdleChangeTextarea}
                  cols="30"
                  rows="5"
                  placeholder={
                    language === "ru"
                      ? localization.ModalHelp.placeholderTextarea.ru
                      : localization.ModalHelp.placeholderTextarea.ua
                  }
                />
                {isShowErrorMessage && (
                  <p className={s.error}>
                    {language === "ru"
                      ? localization.ModalHelp.errorText.ru
                      : localization.ModalHelp.errorText.ua}
                  </p>
                )}

                <div className={s.button_container}>
                  <Button
                    // onClickButton={startGameOrContinue}
                    text={
                      language === "ru"
                        ? localization.ModalHelp.buttonSendQuestion.ru
                        : localization.ModalHelp.buttonSendQuestion.ua
                    }
                    bigButton={true}
                    type="submit"
                  />
                </div>
              </form>
            </div>
            {/* form before success submit - end */}
          </>
        )}
      </section>
    </ModalWrapper>
  );
};

export default ModalHelp;
