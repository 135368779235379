import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import localization from "../../utils/localization";
import s from "./ModalInfo.module.css";
import Button from "../../components/Button/Button";

const ModalInfo = ({ onClose, language }) => {
  const goToTelegramBot = () => {
    window.open("https://t.me/goit_programmer_bot");
  };
  return (
    <ModalWrapper onClose={onClose} hideCloseButton>
      <section className={s.wrapper}>
        <Close className={s.close} onClick={onClose} />
        <h2 className={s.modalTitle}>
          {language === "ru"
            ? localization.ModalInfo.title.ru
            : localization.ModalInfo.title.ua}
        </h2>
        <div className={s.contentWrapper}>
          <p className={s.text}>
            {language === "ru"
              ? localization.ModalInfo.text1.ru
              : localization.ModalInfo.text1.ua}
          </p>

          <div className={s.button_container}>
            <Button
              onClickButton={goToTelegramBot}
              text={
                language === "ru"
                  ? localization.ModalInfo.button.ru
                  : localization.ModalInfo.button.ua
              }
              bigButton={true}
              type="button"
            />
          </div>
        </div>
      </section>
    </ModalWrapper>
  );
};

export default ModalInfo;
