import React, { useState } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./CongratsModal.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
// import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
import localization from "../../utils/localization";
import * as HELPERS from "../../utils/helpers";
import * as API from "../../services/api";
import { toast } from "react-toastify";

const CongratsModal = ({
  onClose,
  language,
  showModalInfo,
  token,
  showLoader,
  hideLoader,
}) => {
  const [userName, setUserName] = useState("");
  const hahdleChangeUserName = (e) => setUserName(e.target.value);
  const [userMobile, setUserMobile] = useState("");
  const hahdleChangeUserMobile = (e) => setUserMobile(e.target.value);

  const [isIncorrectUserName, setIsIncorrectUserName] = useState(false);
  const showErrorIncorrectUserName = () => setIsIncorrectUserName(true);
  const hideErrorIncorrectUserName = () => setIsIncorrectUserName(false);
  const [isIncorrectUserMobile, setIsIncorrectUserMobile] = useState(false);
  const showErrorIncorrectUserMobile = () => setIsIncorrectUserMobile(true);
  const hideErrorIncorrectUserMobile = () => setIsIncorrectUserMobile(false);

  const isPossiblePhoneNumber = (string) => {
    if (string.length < 10) {
      return false;
    }
    const arrSigns = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      " ",
      "-",
      "+",
    ];
    const arrFromString = string.split("");
    // проверка на другие символы кроме тех что есть в массиве arrNumbers
    let countNumbers = 0;
    for (let i = 0; i < arrFromString.length; i++) {
      if (!arrSigns.includes(arrFromString[i])) {
        return false;
      }
      // проверка на то что нету минимум 9ти циф
      if (
        arrFromString[i] !== " " &&
        arrFromString[i] !== "-" &&
        arrFromString[i] !== "+"
      ) {
        countNumbers += 1;
      }
    }

    if (countNumbers < 10) {
      return false;
    }

    return true;
  };

  const onSubmitRegisterForm = (e) => {
    e.preventDefault();
    const isCorrectUserName = userName.length >= 2;
    const isCorrectUserMobile = isPossiblePhoneNumber(userMobile);

    if (isCorrectUserName && isCorrectUserMobile) {
      hideErrorIncorrectUserName();
      hideErrorIncorrectUserMobile();
      const phone = HELPERS.validatePhone(userMobile);
      const name = HELPERS.validateName(userName);

      showLoader();
      API.setUserInfoRequest(token, phone, name)
        .then((res) => {
          if (res.data.success) {
            window.open(
              `https://t.me/goit_programmer_bot?start=TG-JAVA_GAME__PH-${phone}__SRC-DT`
            );
            onClose();
            showModalInfo();
          } else {
            toast.error(
              language === "ru"
                ? localization.CongratsModal.errors.failedRequest.ru
                : localization.CongratsModal.errors.failedRequest.ua,
              {
                autoClose: 10000,
              }
            );
          }
        })
        .catch(() => {
          toast.error(
            language === "ru"
              ? localization.CongratsModal.errors.failedRequest.ru
              : localization.CongratsModal.errors.failedRequest.ua,
            {
              autoClose: 10000,
            }
          );
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      if (!isCorrectUserName && !isCorrectUserMobile) {
        showErrorIncorrectUserName();
        showErrorIncorrectUserMobile();
      } else if (!isCorrectUserName && isCorrectUserMobile) {
        showErrorIncorrectUserName();
        hideErrorIncorrectUserMobile();
      } else if (isCorrectUserName && !isCorrectUserMobile) {
        hideErrorIncorrectUserName();
        showErrorIncorrectUserMobile();
      }
    }
  };

  return (
    <ModalWrapper
      onClose={() => {
        onClose();
      }}
      hideCloseButton
    >
      <section className={s.wrapper}>
        {/* <WinnerCup className={s.winnerCup} width="118" /> */}
        <h2 className={s.modalTitle}>
          {language === "ru"
            ? localization.CongratsModal.title.ru
            : localization.CongratsModal.title.ua}
          &#128522;
        </h2>
        <Close
          className={s.close}
          onClick={() => {
            onClose();
          }}
        />
        <div className={s.contentWrapper}>
          <p className={s.text}>
            {language === "ru"
              ? localization.CongratsModal.text1.ru
              : localization.CongratsModal.text1.ua}
            <span className={s.fatText}>
              {language === "ru"
                ? localization.CongratsModal.text2.ru
                : localization.CongratsModal.text2.ua}
            </span>
          </p>
          <p className={s.text}>
            {language === "ru"
              ? localization.CongratsModal.text3_1.ru
              : localization.CongratsModal.text3_1.ua}
            <span>
              &laquo;
              {language === "ru"
                ? localization.CongratsModal.text3_2.ru
                : localization.CongratsModal.text3_2.ua}
              &raquo;
            </span>
            {language === "ru"
              ? localization.CongratsModal.text3_3.ru
              : localization.CongratsModal.text3_3.ua}
          </p>

          <p className={s.text}>
            {language === "ru"
              ? localization.CongratsModal.text4.ru
              : localization.CongratsModal.text4.ua}
          </p>

          <p className={s.text}>
            <span className={s.fatText}>
              {language === "ru"
                ? localization.CongratsModal.text5_1.ru
                : localization.CongratsModal.text5_1.ua}
            </span>
            {language === "ru"
              ? localization.CongratsModal.text5_2.ru
              : localization.CongratsModal.text5_2.ua}
            <span className={s.fatText}>
              {language === "ru"
                ? localization.CongratsModal.text5_3.ru
                : localization.CongratsModal.text5_3.ua}
            </span>
          </p>

          <p className={s.text}>
            <span className={s.fatText}>
              {language === "ru"
                ? localization.CongratsModal.text6.ru
                : localization.CongratsModal.text6.ua}
            </span>
          </p>

          <form className={s.contact_form} onSubmit={onSubmitRegisterForm}>
            <div className={s.inputContainer}>
              <label htmlFor="userName" className={s.form_inputLabel}>
                {language === "ru"
                  ? localization.CongratsModal.name.label.ru
                  : localization.CongratsModal.name.label.ua}
              </label>
              <input
                id="userName"
                name="userName"
                type="text"
                value={userName}
                onChange={hahdleChangeUserName}
                required
                className={s.form_input}
                placeholder={
                  language === "ru"
                    ? localization.CongratsModal.name.placeholder.ru
                    : localization.CongratsModal.name.placeholder.ua
                }
              />
              {isIncorrectUserName && (
                <p className={s.error}>
                  {language === "ru"
                    ? localization.CongratsModal.errors.userNameIncorrect.ru
                    : localization.CongratsModal.errors.userNameIncorrect.ua}
                </p>
              )}
            </div>

            <div className={s.inputContainer}>
              <label htmlFor="userMobile" className={s.form_inputLabel}>
                {language === "ru"
                  ? localization.CongratsModal.mobile.label.ru
                  : localization.CongratsModal.mobile.label.ua}
              </label>
              <input
                id="userMobile"
                name="userMobile"
                type="tel"
                value={userMobile}
                onChange={hahdleChangeUserMobile}
                required
                className={s.form_input}
                placeholder={
                  language === "ru"
                    ? localization.CongratsModal.mobile.placeholder.ru
                    : localization.CongratsModal.mobile.placeholder.ua
                }
              />
              {isIncorrectUserMobile && (
                <p className={s.error}>
                  {language === "ru"
                    ? localization.CongratsModal.errors.userMobileIncorrect.ru
                    : localization.CongratsModal.errors.userMobileIncorrect.ua}
                </p>
              )}
            </div>

            <div className={s.button_container}>
              <button type="submit" className={s.button}>
                {language === "ru"
                  ? localization.CongratsModal.buttonRegister.ru
                  : localization.CongratsModal.buttonRegister.ua}
              </button>
            </div>
          </form>
        </div>
      </section>
    </ModalWrapper>
  );
};

export default CongratsModal;
