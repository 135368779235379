import React, { useState } from "react";
import styles from "./TaskButtons.module.css";
import { ReactComponent as Refresh } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
// import { ReactComponent as Question } from "../../assets/icons/question/question.svg";
import Timer from "../Timer/Timer";
import VideoModal from "../VideoModal/VideoModal";
import ModalHelp from "../ModalHelp/ModalHelp";
import localization from "../../utils/localization";

const TaskButtons = ({
  checkTest,
  timerEndTime,
  metadata,
  showTimer,
  hideTimer,
  clientWidth,
  // isTheoryAndTaskShow,
  currentTaskId,
  blockTasksArr,
  // getTotalProgress,
  openModalRefreshQuestion,
  openCongratsModal,
  taskSuccessfullyPassed,
  getNextTask,

  showResultArea,
  hideResultArea,
  isShowResultArea,
  language,

  taskblockMetadata,
  token,
  checkUserSeenHelpVideoForTask,
}) => {
  const isLastQuestion =
    blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1;

  const [isShowVideoModal, setIsShowVideoModal] = useState(false);
  const showVideoModal = () => {
    if (!showTimer) {
      setIsShowVideoModal(true);
      checkUserSeenHelpVideoForTask();
    }
  };
  const closeVideoModal = () => setIsShowVideoModal(false);

  const [isShowModalHelp, setIsShowModalHelp] = useState(false);
  // const showModalHelp = () => setIsShowModalHelp(true);
  const closeModalHelp = () => setIsShowModalHelp(false);

  const onClickBtnCheck = () => {
    if (isLastQuestion) {
      checkTest();
    } else {
      checkTest();
    }
  };

  return (
    <div
      style={{ margin: isShowResultArea ? "5px 0" : "0" }}
      className={styles.buttonsContainer}
    >
      {!taskSuccessfullyPassed && (
        <button className={styles.checkButton} onClick={onClickBtnCheck}>
          {language === "ru"
            ? localization.TaskButtons.btnCheck.ru
            : localization.TaskButtons.btnCheck.ua}
        </button>
      )}

      {taskSuccessfullyPassed && !isLastQuestion && (
        <button className={styles.checkButton} onClick={getNextTask}>
          {language === "ru"
            ? localization.TaskButtons.btnNextQuestion.ru
            : localization.TaskButtons.btnNextQuestion.ua}
        </button>
      )}

      {taskSuccessfullyPassed && isLastQuestion && (
        <button className={styles.checkButton} onClick={openCongratsModal}>
          {language === "ru"
            ? localization.TaskButtons.btnEnd.ru
            : localization.TaskButtons.btnEnd.ua}
        </button>
      )}

      <button
        onClick={openModalRefreshQuestion}
        className={styles.buttonWithSvg}
      >
        <Refresh className={styles.svg} />
        {language === "ru"
          ? localization.TaskButtons.btnRefresh.ru
          : localization.TaskButtons.btnRefresh.ua}
      </button>

      {!taskblockMetadata.allowVideoHelp && (
        <button
          onClick={isShowResultArea ? hideResultArea : showResultArea}
          className={styles.buttonWithoutSvg}
        >
          {isShowResultArea
            ? language === "ru"
              ? localization.TaskButtons.btnHideResultArea.ru
              : localization.TaskButtons.btnHideResultArea.ua
            : language === "ru"
            ? localization.TaskButtons.btnShowResultArea.ru
            : localization.TaskButtons.btnShowResultArea.ua}
        </button>
      )}

      {taskblockMetadata.allowVideoHelp && (clientWidth > 1300 || !showTimer) && (
        <button
          onClick={showVideoModal}
          className={
            !showTimer ? styles.buttonWithSvg : styles.disableButtonWithSvg
          }
        >
          <Movie className={!showTimer ? styles.svg : styles.disableSvg} />
          {language === "ru"
            ? localization.TaskButtons.btnVideo.ru
            : localization.TaskButtons.btnVideo.ua}
        </button>
      )}

      {/* <Question className={styles.svgQuestion} onClick={showModalHelp} /> */}

      {taskblockMetadata.allowVideoHelp && showTimer && (
        <Timer
          timerEndTime={timerEndTime}
          hideTimer={hideTimer}
          language={language}
        />
      )}

      {isShowVideoModal && (
        <VideoModal metadata={metadata} onClose={closeVideoModal} />
      )}

      {isShowModalHelp && (
        <ModalHelp language={language} onClose={closeModalHelp} token={token} />
      )}
    </div>
  );
};

export default TaskButtons;
