import React from "react";
import s from "./Button.module.css";

const Button = ({
  text = "Button",
  onClickButton = () => null,
  bigButton = false,
  type = "button",
}) => {
  return (
    <button
      className={bigButton ? s.bigButton : s.button}
      onClick={onClickButton}
      type={type}
    >
      {text}
    </button>
  );
};

export default Button;
