export const validatePhone = (phone) => {
  const arrSigns = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const arrFromPhoneString = phone.split("");
  let resultPhone = [];
  for (let i = 0; i < arrFromPhoneString.length; i++) {
    if (arrSigns.includes(arrFromPhoneString[i])) {
      resultPhone.push(arrFromPhoneString[i]);
    }
  }
  return resultPhone.reduce((acc, el) => acc + el, "");
};

export const validateName = (name) => {
  let resultName = name;
  if (name.length > 12) {
    resultName = name.slice(0, 20);
  }
  return resultName;
};
